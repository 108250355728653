<template>
  <div id="container" class="gnb_pdt">
    <div class="title_wrap"><h1>서비스신청관리 {{ isNew ? '등록' : '수정'}}</h1></div>
    <div class="contents_wrap">
      <div class="small_title_wrap"><h2 >신청자정보</h2></div>
      <ul class="filter_list">
        <li>
          <dd><label>신청회사명</label>
            <ul>
              <li>
                {{ svcReqInfo.cmpyNm }}
                <button @click="$refs.searchCompanyWindowRef.kendoWidget().center().open()"
                        class="small_btn mgl10">회사검색</button>
              </li>
            </ul>
          </dd>
          <dd v-if="!isNew"><label>신청번호</label>
            <ul>
              <li>{{ svcReqInfo.svcReqNo}}</li>
            </ul>
          </dd>
        </li>
        <li>
          <dd><label>신청자명</label>
            <ul>
              <li>
                {{ svcReqInfo.reqUserNm }}
                <button @click="$refs.userSearchWindowRef.kendoWidget().center().open()"
                        class="small_btn mgl10">신청자 검색</button>
              </li> 
            </ul>
          </dd>
          <dd ><label>신청자연락처</label>
            <ul>
              <li>{{ svcReqInfo.spTelNo}}</li>
            </ul>
          </dd>
        </li>
        <li>
          <dd><label>승인상태</label>
            <ul>
              <li>
                {{ svcReqInfo.apprvStatusNm }}
              </li>
            </ul>
          </dd>
        </li>
      </ul>
    </div>
    <div class="contents_wrap">
      <div class="small_title_wrap">
        <h2> 물류/상품정보</h2>
        <ul class="filter_list">
          <li>
            <dd><label>물류운영형태<span class="forg"> *</span></label>
              <ul>
                <li>
                  <kendo-dropdownlist
                      ref="logiTypeNmRef"
                      v-model.trim="svcReqInfo.logiType"
                      :data-source="$store.state.codeRequestStore.search.combobox.logiType.data"
                      :data-text-field="'cdNm'"
                      :data-value-field="'cdId'"
                      :option-label="'선택'"
                      class="w80"
                  >
                  </kendo-dropdownlist>
                </li>
              </ul>
            </dd>
          </li>
          <li>
            <dd ><label>주요운영상품<span class="forg"> *</span></label>
              <ul>
                <li>
                  <input
                      ref="mainGoodsTextRef"
                      class="k-textbox w80"
                      v-model.trim="svcReqInfo.mainGoodsText"
                  />
                </li>
              </ul>
            </dd>
            <dd><label>상품종류</label>
              <ul>
                <li>
                  <kendo-numerictextbox ref="goodsTypeCntRef"
                                        class="w80"
                                        :format="'###,#'"
                                        v-model="svcReqInfo.goodsTypeCnt"
                                        :max="99999"
                                        :min="0">
                  </kendo-numerictextbox>
                </li>
              </ul>
            </dd>
          </li>
          <li>
            <dd><label>상품수량</label>
              <ul>
                <li>
                  <kendo-numerictextbox ref="finanRateRef"
                                        class="w80"
                                        :format="'###,#'"
                                        v-model="svcReqInfo.goodsCnt"
                                        :max="99999"
                                        :min="0">
                  </kendo-numerictextbox>
                </li>
              </ul>
            </dd>
            <dd ><label>월 예상 출고건수<span class="forg"> *</span></label>
              <ul>
                <li>
                  <kendo-dropdownlist
                      ref="expectMonthOutCntNmRef"
                      v-model.trim="svcReqInfo.expectMonthOutCnt"
                      :data-source="$store.state.codeRequestStore.search.combobox.expectMonthOutCnt.data"
                      :data-text-field="'cdNm'"
                      :data-value-field="'cdId'"
                      :option-label="'선택'"
                      class="w80"
                  >
                  </kendo-dropdownlist>
                </li>
              </ul>
            </dd>
          </li>
        </ul>
      </div>
    </div>
    <div class="contents_wrap">
      <div class="small_title_wrap">
        <h2>서비스 등급/기능 정보</h2>
      </div>
      <div>
        <table  border="0" cellpadding="0" cellspacing="0" class="basic_table mgt20">
          <colgroup >
            <col width="25%">
            <col width="25%">
            <col width="25%">
            <col width="25%">
          </colgroup>
          <tr>
            <th>서비스등급</th>
            <th>3D VIEW 적용여부</th>
            <th>미디어 저장 여부</th>
            <th>수기등록여부</th>
          </tr>
          <tbody>
          <tr>
            <template v-for="item in svcReqInfo.svcAddOnInfoResponseList">
              <td v-if="item.svcGbn === constant.svcGbn.main">
                <kendo-dropdownlist
                    ref="svcGbnRef"
                    v-model.trim="item.svcNo"
                    :data-source="commonSvcMainListResponseList"
                    :data-text-field="'svcGradeNm'"
                    :data-value-field="'svcNo'"
                    :option-label="'선택'"
                    style="width: 100%;"
                >
                </kendo-dropdownlist>
              </td>
              <td v-else-if="item.svcGbn === constant.svcGbn.add">
                <common-checkbox-yn
                    v-model="item.svcYn">
                </common-checkbox-yn>
              </td>
            </template>
            <td class="txt_c">{{ svcReqInfo.handflagYn }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!--- 버튼영역 --->
    <div class="button_area w25">
      <ul>
        <li class="w30">
          <button class="large_btn"
                  @click="$router.go(-1)">취소</button>
        </li>
        <li class="w30">
          <button class="large_btn orange"
                  @click="onClickSaveSvcReq">저장</button>
        </li>
      </ul>
    </div>
    <kendo-window
        ref="userSearchWindowRef"
        :title="'신청자검색'"
        :modal="true"
        :width="'700px'"
        style="display:none; min-height: 500px;"
        @onPushUser="updateMngUser"
        @open="e=>{this.isUserSearchWindowOpen = true}"
        @close="e=>{this.isUserSearchWindowOpen = false}"
    >
      <component :is="isUserSearchWindowOpen ? 'SearchUser' : null"
                 :search-yn="'Y'"
                 :search-cmpy-cd="svcReqInfo.cmpyCd"></component>
    </kendo-window>

    <kendo-window
        ref="searchCompanyWindowRef"
        :title="'회사검색'"
        :modal="true"
        :width="'700px'"
        style="display:none; min-height: 600px;"
        @onPushCmpy="updateCmpy"
        @open="e=>{this.isCmpySearchWindowOpen = true}"
        @close="e=>{this.isCmpySearchWindowOpen = false}"
    >
      <component :is="isCmpySearchWindowOpen ? 'SearchCompany' : null"
                 :selectable="'row'">
      </component>
    </kendo-window>
  </div>
</template>

<script>
import ApiUtil from '@/api/api.util'
import ApiConfig from '@/api/api.config'
import SearchUser from '@/components/SearchUser'
import SearchCompany from '/src/components/SearchCompany'
import EtnersCommonUtil from '@ebmp-fe-common-module/module/src/common/etners.common.util'

export default {
  name   : 'ServiceRequestManageSave',
  components : {
    SearchUser,
    SearchCompany
  },
  async created () {
    const vm = this
    await vm.getSvcPricing()
    if (!!this.$route.params.svcReqNo) {
      this.isNew = false
      vm.getSvcReqMngDetail()
    }
  },
  methods : {
    updateCmpy : function (newCmpy){
      const vm = this
      let cmpy = newCmpy[0]
      vm.svcReqInfo.cmpyCd = cmpy.cmpyCd
      vm.svcReqInfo.cmpyNm = cmpy.cmpyNm

      vm.updateMngUser([{
        reqUnqUserId : null,
        reqUserNm: null,
        spTelNo: null }])
    },
    updateMngUser : function (newMngUser){
      const vm = this
      let mngUser = newMngUser[0]
      vm.svcReqInfo.reqUnqUserId = mngUser.unqUserId
      vm.svcReqInfo.reqUserNm = mngUser.userNm
      vm.svcReqInfo.spTelNo = mngUser.spTelNo
    },
    getSvcReqMngDetail : function() {
      const vm = this
      ApiUtil.query(`${ApiConfig.efsDomain}/efs/manage/svc/${vm.$route.params.svcReqNo}`)
      .then(function (response) {
            if (response.data.resultStatus.messageCode === '2000') {
              vm.svcReqInfo = response.data.resultData
            } else {
              kendo.alert(response.data.resultStatus.messageText)
            }
          },
      )
    },
    getSvcPricing() {
      const vm = this
      ApiUtil.query(`${ApiConfig.efsDomain}/efs/svc/pricing`)
      .then(function (response) {
            if (response.data.resultStatus.messageCode === '2000') {
              vm.commonSvcAddListResponseList = response.data.resultData.commonSvcAddListResponseList
              vm.commonSvcMainListResponseList = response.data.resultData.commonSvcMainListResponseList
              if(vm.isNew)vm.svcReqInfo.svcAddOnInfoResponseList.push(...vm.commonSvcAddListResponseList)
            } else {
              kendo.alert(response.data.resultStatus.messageText)
            }
          },
      )
    },
    onClickSaveSvcReq : function (){
      const vm = this

      if(validator()){
        kendo.confirm('저장하시겠습니까?').done(
            function () {
              vm.saveSvqReq()
            })
      }

      function validator(){
        if(!vm.svcReqInfo.cmpyCd){
          kendo.alert("회사를 조회해주세요.").bind('close', function() {
            vm.$refs.searchCompanyWindowRef.kendoWidget().center().open()
          })
          return false
        }
        if(!vm.svcReqInfo.reqUnqUserId){
          kendo.alert("신청자를 조회해주세요.").bind('close', function() {
            vm.$refs.userSearchWindowRef.kendoWidget().center().open()
          })
          return false
        }
        if(!vm.svcReqInfo.logiType){
          kendo.alert("물류운영형태를 선택해주세요.").bind('close', function() {
            vm.$refs.logiTypeNmRef.kendoWidget().toggle()
          })
          return false
        }
        if(!vm.svcReqInfo.logiType){
          kendo.alert("주요운영상품을 입력해주세요.").bind('close', function() {
            vm.$refs.mainGoodsTextRef.focus()
          })
          return false
        }
        if(!vm.svcReqInfo.expectMonthOutCnt){
          kendo.alert("월 예상 출고건수를 선택해주세요.").bind('close', function() {
            vm.$refs.expectMonthOutCntNmRef.kendoWidget().toggle()
          })
          return false
        }
        let existMainSvc = false
        vm.svcReqInfo.svcAddOnInfoResponseList.every(item=>{
          if(item.svcGbn === vm.constant.svcGbn.main){
            existMainSvc = !!EtnersCommonUtil.isNotEmpty(item.svcNo);
            return false
          }else return true
        })
        if(!existMainSvc){
          kendo.alert("서비스등급을 선택해주세요.").bind('close', function() {
            vm.$refs.svcGbnRef[0].kendoWidget().toggle()
          })
          return false
        }

        return true
      }
    },
    saveSvqReq : function (){
      const vm = this

      vm.svcReqInfo.svcAddOnInfoResponseList =
          vm.svcReqInfo.svcAddOnInfoResponseList.filter(item=>
              item.svcGbn === vm.constant.svcGbn.main || item.svcYn === 'Y'
          )

      if(vm.isNew){
        ApiUtil.post(`${ApiConfig.efsDomain}/efs/manage/svc`, vm.svcReqInfo)
        .then(function (response) {
              if (response.data.resultStatus.messageCode === '2000') {
                vm.$router.push({
                  name: 'ServiceRequestManageDetail',
                  params : {svcReqNo : response.data.resultData.svcReqNo}
                })
              } else {
                kendo.alert(response.data.resultStatus.messageText)
              }
            },
        )
      }else {
        ApiUtil.put(`${ApiConfig.efsDomain}/efs/manage/svc/${vm.$route.params.svcReqNo}`, vm.svcReqInfo)
        .then(function (response) {
              if (response.data.resultStatus.messageCode === '2000') {
                vm.$router.push({
                  name: 'ServiceRequestManageDetail',
                  params : {svcReqNo : vm.$route.params.svcReqNo}
                })
              } else {
                kendo.alert(response.data.resultStatus.messageText)
              }
            },
        )
      }
    },
  },
  data   : function () {
    const headerAttributes = { style: 'font-weight: bold; text-align: center;' }
    const centerAttributes = { style: 'text-align: center;' }
    return {
      isNew : true,
      isCmpySearchWindowOpen : false,
      isUserSearchWindowOpen : false,
      constant : { svcGbn : { main : '01', add :'02' } },
      commonSvcAddListResponseList : [],
      commonSvcMainListResponseList : [],
      svcReqInfo  : {
        svcAddOnInfoResponseList :[
          { svcGbn: '01'}]
      },
    }
  },
}
</script>
